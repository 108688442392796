/*
 * Palette de couleurs:
 *
 * - Hex: #BB4263, CMYK: 20 84 39  9, RGB 188  66  99, rouge
 * - Hex: #694189, CMYK: 71 83  9  1, RGB 106  65 137, violet
 * - Hex: #F8B038, CMYK:  0 36 84  0, RGB 248 176  56, jaune
 * - Hex: #27213C, CMYK: 91 89 43 54, RGB  39  32  59, noir
 * - Hex: #F7F2F5, CMYK:  4  6  3  0, RGB 247 242 245, blanc
 * */
.rouge            { color:            #BB4263; }
.violet           { color:            #694189; }
.jaune            { color:            #F8B038; }
.noir             { color:            #27213C; }
.blanc            { color:            #F7F2F5; }
.backgroundrouge  { background-color: #BB4263; }
.backgroundviolet { background-color: #694189; }
.backgroundjaune  { background-color: #F8B038; }
.backgroundnoir   { background-color: #27213C; }
.backgroundblanc  { background-color: #F7F2F5; }

.grey             { color:            grey   ; }
.green            { color:            green  ; }

/*  white = '#ffffff',
  whiteLight = '#efefef',
  black = '#222222',
  pink = '#E76372',
  red = '#c13515',
  green = '#00A699',
  greyLighter = '#ebebeb',
  greyLight = '#dddddd',
  grey = '#b0b0b0',
  blackLight = '#484848', // for texts
  greyBlack = '#717171', // for placeholders
  backgroundColor = '#fff',
  blackPlaceholder = '#0d0d0d',*/
/*Reusable class*/


.bleu-chart {color:#484848 !important;}
.bleu-chart-bg {background-color:#2e266d !important;}
.orange-chart {color: #f6b138;}
.light-chart {color: #befaf6;}
.jaune-chart-bg {background-color: #fdf9de;}
.light-chart-bg {color: #befaf6;}
.whiteColor{color: '#ffffff' /*white;*/ !important;}
.silverColor {color: silver!important;}
.green-text {color: #00A699 /*green*/;}
.red-text {color: #c13515 /*red*/;}

@font-face {
  font-family: "Poppins";
  src: url("assets/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: "montserrat";
  src: url("assets/Montserrat/Montserrat-Medium.ttf");
}
body {
  margin: 0;
  font-family: 'montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfbff;
  /*
  background-color: #f7f2f5ff;
   * */
}
.App {
  text-align: center;
  /*
    background-color: #F7F2F5ff;
   * */
}
.mainAll {
  min-height: 100vh;
}
.justificatif {
    background: '#ffffff'; /*white;*/
    padding: 10px;
    margin: 10px !important;
    /* list-style-type: none; */
}
.backjustif {
    border: 5px solid '#ffffff'; /*white;*/
}
.justificatif input.active {
    /*
    color: #f4ba51 ;
     * */
    color: #484848 ;
}
.back_justif {
    width:  14em;
    height: 10em;
    background-size:     contain;
    background-repeat:   no-repeat;
    background-position: center;
}
.recto_cni   { background-image: url("./assets/recto_cni.jpg"); }
.passeport   { background-image: url("./assets/passeport.jpg"); }
@media screen and (max-width: 992px) {
  .mainAll {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;
  }
}
a {
  color: #484848;
}
button > span {
  font-size: 0.8rem;
}
input[type=file]{
  display: none;
}
ul, li {
  list-style: none;
}
.bold {
  font-weight: bold;
}
.input-file-cta{
  background-color: #00adb5 !important;
  color: '#ffffff' /*white;*/ !important;
  padding: 5% !important;
}
.cursor-pointer { 
  cursor: pointer;
}

.height-100 {
  height: 100vh;
}
.font-little {
  font-size: 0.6rem;
}
.font-medium {
  font-size: 0.75rem;
}
.bx-shadow{
  box-shadow: silver 3px 5px 5px;
}
.bx-shadow-little {
  box-shadow: silver 2px 3px 5px;
}

.landing {
    text-align: center;
    min-height: 100vh;
    width: 100vw;
    /*
    background: linear-gradient(to right bottom, #F6b138, #Fdf9de);
    background: linear-gradient(to right bottom, #efefef, #484848);
     */
    background: #f7f2f5ff;
}
.login-banner {
    color: #27213cff;
}
/* background: linear-gradient(to right bottom, #FFC75F, #D53569);*/
.landing > h1 {
  padding: 10% 0 5% 0;
  font-size: 4rem;
}

.navbar {
  z-index: 2;
  padding-bottom: 0;
}
.navbar-bg {
    /*
  background-color: yellow;
     * */
}
.MuiAppBar-colorPrimary{
    background-color: #fff !important;
    color: #222222 /*black*/ !important;
    z-index: 2;
}
.MuiDrawer-paperAnchorDockedLeft {
  box-shadow: silver 5px 5px 5px !important;
}
.makeStyles-drawerPaper-5 {
  z-index: 1 !important;
}
.blue-erasm-bg {
  background-color: #3B61EF;
}
.grey-erasm-bg {
  background-color: #393E46;
}
.gold-erasm-bg {
  background-color: #d98759;
}
.pink-erasm-bg {
  background-color: #F63A83;
}
.light-pink-erasm-bg {
  background-color:#F198C2;
}
.old-blue{
  background-color: #05172C;
}
/*HEADER*/
#nbrCredits-id{
    padding: 0 5px;
}
/*Side BAR*/
.navbar * {
  color: #222222 /*black*/;
}
li {
  color: #222222 /*black*/ !important;
}
.sideshadow {
  box-shadow: 5px 0 5px -2px silver;
}
.sidebar{
  /*
    padding: 50px 2%;
   * */
  display: flex;
  flex-direction: column;
  height: 100vh;
}
@media screen and (max-width: 992px) {
  .sidebar {
    display: flex;
    height: auto;
  }
}
.btn-oeuvre:hover {
  background-color: #05172C;
}
.btn-protection:hover {
  background-color: #F198C2;
}
.oeuvre_protege_icon {
    /*
  width: 25px;
  height: auto;
     * */
  height: 45px;
}

/*stepper-general*/
.stepper-general{
  width:100%;
  margin: auto;
}

.card-author{
  background-color: #F6f6f6;
  padding: 3%;
  box-shadow: silver 2px 3px 5px;
  border-radius: 10px;
  margin: 3% auto;
}
/*Remaniement (provisoire ?) des modales pour la baisser et la centrer au milieu de l'écran*/
.modal-content{
    margin-top: 15vh;
}

/*TABLE*/
th {
    border:transparent !important;
}

/*Profil verif*/
.logo-identity {
  width: 400px;
  height: auto;
} 
/* tFields from Methanol*/
.input-label-inline {
  width: 60%;
  margin: auto;
}

.hidden { display: none }

.toast-container {
  position: absolute;
  bottom: 0;
  right:0;
  z-index: 1000;
  width:300px;
  justify-self: flex-end !important;
}
.methanol-popover {
  background: #3f51b5;
  color: '#ffffff'; /*white;*/
}

/*
.dropdown-menu{
  left: inherit !important;
  right: 0 !important;
}  
*/

.form-control:disabled, .form-control[readonly] {
  background-color: lightgrey
}

:disabled {
  background-color: lightgrey
}
/* FOOTER */
.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  color: #F7F2F5;
  background-color: #000000;
  text-align: center;
}
.footer a {
  color: #F7F2F5 !important;
}
.footer li {
  color: #F7F2F5 !important;
}
.copyright {
  margin-top: 3%;
  color: #0d6efd;
  font-size: 16px;
}
.main-dashboard {
    width:     90%;
    min-width: 800px;
    max-width: 1024px;
}
/*
@keyframes gogogogo {
  0%   {left:0px; top:200px;}
  50%  {left:0px; top:100px;}
  100% {left:0px; top:200px;}
}
 * */
@keyframes heartbeat {
    0%   { transform: scale( 0.90 ); }
    20%  { transform: scale( 0.95 ); }
    30%  { transform: scale( 0.93 ); }
    40%  { transform: scale( 1.00 ); }
    100% { transform: scale( 0.96 ); }
}

.CenterMe {
    /*
    height:    6em;
     * */
    max-width: 1024px;
    margin:    auto;
}

.center-content {
    max-width: 1024px;
    margin:    auto;
}

.box__input {
    /* outline: 2px dashed black; */
    border: 1px dashed #27213cff;
    border-radius: 6px;
    /* outline-offset: -10px; */
    padding: 1rem 5rem;
}

.no-dragover {
    /*
  background-color: rgba(204,209,209,0.2);
     * */
  background-color: #F7F2F5
}
.is-dragover {
  background-color: lightgreen;
}

.fichiers_a_proteger  {
    background-color: rgba(204,209,209,0.2);
    padding: 50px;
    width: 800px;
    border-radius: 6px;
}

.liseret {
    border-left-width: 10px;
    border-left-color: #0073bc;
    border-left-style: solid;
}
/*
.MuiPaper-root      { background-color: rgba( 39, 32, 59,1); }
.MuiStepIcon-active { background-color: rgba(106  65 137,1); }
.MuiStepIcon-root   { background-color: rgba( 30, 32, 59,1); }
.MuiSvgIcon-root    { background-color: rgba( 30, 32, 59,1); }
.MuiSvgIcon-root .MuiStepIcon-root .MuiStepIcon-active { background-color: rgba( 30, 32, 59,1); }
 * */

.fondblanc {
    background: #74bc7333;
}

/* input style **/

.inputMargin {
  margin-bottom: 22px;
  text-align: left;
}

.inputContainer {
  background-color: #fcfcfc;
  color: rgb(34, 34, 34) !important;
  cursor: text !important;
  width: 100% !important;
  border-radius: 8px !important;
  overflow: hidden;
  padding: 5px 11px;
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
}

.containerFlexCol {
  display: flex !important;
  flex-direction: column;
}

.inputContainerFlexRow {
  display: flex !important;
  flex-direction: row;
}

.inputContainerFlexRow {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.inputKeyW {
  width: 100%;
  padding-right: 10px;
}

.inputLabel {
  font-size: 12px;
}

.inputStyle {
  padding: 0 !important;
  border: none;
  background: #fcfcfc !important;
}

.inputStyle:focus {
  outline: none;
}

.btnGreen, .btn-primary {
  background-color: #2374AB !important;
  border: 1px solid #127475ff !important;
  border-radius: 3px !important;
  padding: 6px 30px !important; 
  color: #f7f2f5ff !important;
  font-size: 14px !important;
  font-weight: 600;
}

.btnBorder, .btn-secondary {
  background-color: #f7f2f5ff !important;
  border: 1px solid #27213cff !important; 
  border-radius: 3px !important;
  padding: 6px 30px !important; 
  color: #27213cff !important;
  font-size: 14px !important;
  font-weight: 600;
}

.btnBorderGreen {
  background-color: #f7f2f5ff !important;
  border: 1px solid #127475ff !important; 
  border-radius: 3px !important;
  padding: 6px 30px !important; 
  color: #27213cff !important;
  font-size: 14px !important;
}

.btnBorderGreen > span, .btnGreen > span {
  font-size: 14px;
}

.btnTextGreen {
  background-color: transparent !important;
  border-radius: 0 !important;
  border: none !important; 
  padding: 1px 0px !important; 
  color: #27213cff !important;
  border-bottom: 1px solid #127475ff !important; 
  font-size: 16px !important;
  width: fit-content !important;
}

.btnIcon {
  background-color: transparent !important;
  border-radius: 0 !important;
  border: none !important; 
  padding: 1px 2px !important; 
  color: #27213cff !important;
  font-size: 16px !important;
}

.textTransform {
  text-transform: none !important;
}

.authBackground {
  background-color: #f7f2f5ff !important;
  background: #f7f2f5ff !important;
}

.secondaryText {
  font-size: 11px !important;
  color: #5d5c63;
}

.MuiToggleButtonGroup-groupedHorizontal:not(:last-child), .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
  border-radius: 3px !important;
  border: 2px solid rgba(0, 0, 0, 0.12) !important
}

.MuiToggleButton-root.Mui-selected {
  border: 3px solid #2374abff !important;
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.Mui-disabled { opacity: 50% }

.modal-content, .MuiPaper-root, .dropdown-menu {
  background-color: #f7f2f5ff !important;
}

.MuiTypography-body2, .MuiMenuItem-root, .MuiStepIcon-text, .MuiSelect-select {
  font-family: 'montserrat', sans-serif !important
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #2374abff !important;
}

.MuiStepIcon-root {
  color: #b0b0b0 !important;
}

.pagination {
  margin-top: 24px !important;
}

.pagination > span {
  font-size: 16px !important;
}

.inputNumStyle {
  width: 55px !important;
  border: none !important;
  border-bottom: 1px solid #b0b0b0 !important;
  background-color:inherit !important;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

th, tr{
  font-size: 14px !important;
}

.qrinthelist:hover {
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    -o-transform: scale(2);
    -webkit-transform: scale(2);
    transform: scale(2);
}

